import React from 'react'

import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';

export default class CustomDropdown extends React.Component {
  componentWillReceiveProps(nextProps){
    this.setState(nextProps)
  }

  _renderCustom(){
    return (
      <Dropdown
          options={this.props.options}
          className={this.props.className}
          value={this.props.option}
          onChange={option => this.props.onChange(option)}
          placeholder={this.props.placeholder}
          placeholderClassName={this.props.placeholderClassName}
      />
    )
  }

  _renderOptionGroup(group){
    return (
      <optgroup label={group.name}>
        { group.items.map(option => this._renderOption(option)) }
      </optgroup>
    )
  }

  _renderOption(option){
    return (
      <option
        value={option.value}
        className={option.className || ""}
      >
        { option.label }
      </option>
    )
  }

  render() {
    if(this.props.custom){
      return this.props._renderCustom()
    }

    let value = this.props.value ? this.props.value.value : null

    return (
      <div className="input-select">
        <select
          value={value}
          className={this.props.value ? this.props.value.className : ""}
          onChange={e => {
            let value = e.target.value
            let selectedOption = null

            for(var i = 0; i < this.props.options.length; i++){
              var option = this.props.options[i]
              if(option.type === "group"){
                for(var j = 0; j < option.items.length; j++){
                  var item = option.items[j]
                  if(item.value == value){
                    selectedOption = item
                    break
                  }
                }
              }
              if(option.value == value){
                selectedOption = option
                break
              }
            }

            this.props.onChange(selectedOption)
          }}
        >
          { this.props.options.map(option => {
              if(option.type === "group"){
                return this._renderOptionGroup(option)
              }
              return this._renderOption(option)
            })
          }
        </select>
      </div>
    )
  }
}

CustomDropdown.defaultProps = {
  custom: false,
  className: ""
}
